<template>
    <div id="camera-add">
        <sui-form-field required :error="error.errorName">
            <label>Name</label>
            <input type="text" name="name" placeholder="Name" v-model="camera.name">
        </sui-form-field>
        <sui-form-field required :error="error.errorIntercomNumber">
            <label>Intercom Number</label>
            <input type="text" name="intercom-number" placeholder="Intercom Number" v-model="camera.intercomNumber">
        </sui-form-field>

        <sui-form-field>
            <label>Location</label>
            <sui-form-fields required>
                <sui-form-field :width="12">
                    <sui-dropdown
                            fluid
                            :options="locationOptions"
                            placeholder="Select Location"
                            search
                            selection
                            :loading="loadingLocation"
                            v-model="camera.location"
                    />
                </sui-form-field>
                <sui-form-field :width="4">
                    <sui-button @click.prevent="goToNewLocation">Create New Location</sui-button>
                </sui-form-field>
            </sui-form-fields>
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">Property Manager who owns this location will automatically grant the permission to
                this camera.</p>
        </sui-form-field>

        <sui-form-field>
            <label>Viewer</label>
            <sui-form-fields required>
                <sui-form-field :width="12">
                    <sui-dropdown
                            fluid
                            :options="usersOptions"
                            placeholder="Select Viewer"
                            search
                            selection
                            :loading="loadingUsers"
                            multiple
                            v-model="camera.viewer"
                    />
                </sui-form-field>
                <sui-form-field :width="4">
                    <sui-button @click.prevent="goToNewAccess">Create New Access</sui-button>
                </sui-form-field>
            </sui-form-fields>
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">Viewers can only view the live stream of this camera and access to the video
                clips.</p>
        </sui-form-field>
        <sui-form-field>
            <label>Manager</label>
            <sui-form-fields required>
                <sui-form-field :width="12">
                    <sui-dropdown
                            fluid
                            :options="managersOptions"
                            placeholder="Select Manager"
                            search
                            selection
                            :loading="loadingManager"
                            multiple
                            v-model="camera.manager"
                    />
                </sui-form-field>
                <sui-form-field :width="4">
                    <sui-button @click.prevent="goToNewAccess">Create New Access</sui-button>
                </sui-form-field>
            </sui-form-fields>
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">Managers can edit this camera and assign it to specific location.</p>
        </sui-form-field>
        <camera-form-admin :camera="camera" :is-admin="isAdmin"></camera-form-admin>
    </div>
</template>

<script>
    import axios from "axios";
    import _ from 'lodash';
    import CameraFormAdmin from "@/views/dashboard/camera/CameraFormAdmin";

    export default {
        name: "CameraForm",
        components: {CameraFormAdmin},
        props: {
            camera: {
                name: String,

                intercomNumber: String,
                location: Number,
                viewer: Array,
                manager: Array,

                awsSecret: String,
                awsKey: String,
                awsRegion: String,
                awsChannel: String,

                launchCommand: String,

                sipNumber: String,
                sipPassword: String,

                deviceId: String,
            },

            error: {
                errorName: Boolean,
                errorIntercomNumber: Boolean,
            },
        },
        data: function () {
            return {
                locationOptions: [],
                usersOptions: [],
                managersOptions: [],

                loadingLocation: true,
                loadingUsers: true,
                loadingManager: true,

                isAdmin: false
            }
        },
        created: function () {
            this.throttledLoadLocations = _.throttle(this.loadLocations, 1000, {trailing: false})
            this.throttledLoadUsers = _.throttle(this.loadUsers, 1000, {trailing: false})

            this.getUserRole();
            this.loadUsers();
            this.loadLocations();
            this.loadManagers();
        },
        methods: {
            getUserRole: async function () {
                const role = await this.$config.userRole();
                console.log(role);
                this.isAdmin = role === "AS";
            },
            goToNewLocation: function () {
                let routeData = this.$router.resolve({name: 'LocationAdd'});
                window.open(routeData.href, '_blank');
            },
            goToNewAccess: function () {
                let routeData = this.$router.resolve({name: 'AccessAdd'});
                window.open(routeData.href, '_blank');
            },
            loadLocations: function () {
                console.log("loadLocations");
                axios({
                    "method": "POST",
                    url: "/api/location/list",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        limit: 0,
                        offset: 0,

                        title: "",
                        address: "",
                        status: "",
                        createdBy: "",
                    }
                }).then((result) => {
                    const {rows, count} = result.data;
                    console.log(rows);
                    console.log(count);
                    this.locationOptions = rows.map((i) => {
                        return {
                            value: i.id,
                            text: i.title
                        }
                    });
                    this.loadingLocation = false;
                    console.log(`current location: ${this.camera.location}`);
                }).catch((e) => {
                    console.log(e);
                });
            },


            loadUsers: function () {
                console.log("loadUsers");
                axios({
                    method: "POST",
                    url: "/api/user/list",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        limit: 0,
                        offset: 0,

                        firstName: "",
                        lastName: "",
                        email: "",
                        status: "",
                        role: "S",
                    }
                }).then((response) => {
                    const {rows, count} = response.data;
                    console.log(rows);
                    console.log(count);
                    this.usersOptions = rows.map((i) => {
                        return {
                            value: i.id,
                            text: `[${i.role}] ${i.firstName} ${i.lastName} - ${i.email}`
                        }
                    });
                    this.loadingUsers = false;
                    console.log(`current viewer: ${this.camera.viewer}`);
                });
            },
            loadManagers: function () {
                console.log("loadUsers");
                axios({
                    method: "POST",
                    url: "/api/user/list",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        limit: 0,
                        offset: 0,

                        firstName: "",
                        lastName: "",
                        email: "",
                        status: "",
                        role: "M",
                    }
                }).then((response) => {
                    const {rows, count} = response.data;
                    console.log(rows);
                    console.log(count);
                    this.managersOptions = rows.map((i) => {
                        return {
                            value: i.id,
                            text: `[${i.role}] ${i.firstName} ${i.lastName} - ${i.email}`
                        }
                    });
                    this.loadingManager = false;
                    console.log(`current manager: ${this.camera.manager}`);
                });
            }
        }

    }
</script>

<style scoped>
    p.field-detail {
        margin-top: -10px;
        color: grey;
    }
</style>
