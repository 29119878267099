<template>

    <div id="add-camera">

        <h2 is="sui-header" class="dividing">Add Camera</h2>
        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <camera-form :camera="camera" :error="errorWarning"></camera-form>
        </sui-form>

        <sui-divider></sui-divider>
        <sui-button type="submit" color="blue" @click.prevent="save">Create Camera</sui-button>
        <sui-button floated="right" @click.prevent="cancel">Cancel</sui-button>

    </div>
</template>

<script>
    import axios from "axios";

    import CameraForm from "@/views/dashboard/camera/CameraForm";
    import SuccessMessage from "@/components/SuccessMessage";
    import ErrorMessage from "@/components/ErrorMessage";

    export default {
        name: "CameraAdd",
        components: {CameraForm, SuccessMessage, ErrorMessage},
        data: function () {
            return {
                camera: {
                    name: "",
                    intercomNumber: "",

                    location: null,
                    viewer: [],
                    manager: [],

                    awsSecret: "",
                    awsKey: "",
                    awsRegion: "",
                    awsChannel: "",

                    launchCommand: "",

                    sipNumber: "",
                    sipPassword: "",

                    deviceId: "",
                },

                errorWarning: {
                    errorName: false,
                    errorIntercomNumber: false,
                },

                loading: false,

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },
        methods: {
            cancel: function () {
                this.$router.push('/dashboard/camera');
            },
            formValidation: function () {
                let pass = true;
                if (this.camera.name.trim() === "") {
                    this.errorList.push("Please give this camera a name.");
                    this.errorWarning.errorName = true;
                    pass = false;
                }
                if (this.camera.intercomNumber.trim() === "") {
                    this.errorList.push("Please provide an intercom number for this camera.");
                    this.errorWarning.errorIntercomNumber = true;
                    pass = false;
                }
                if (pass) {
                    this.errorTitle = "";
                    this.errorList = [];
                    this.errorWarning.errorName = false;
                    this.errorWarning.errorIntercomNumber = false;
                } else {
                    this.errorTitle = "Please provide detail information";
                }

                return pass;
            },
            save: function () {
                this.loading = true;
                if (this.formValidation() === false) {
                    this.loading = false;
                    return;
                }

                axios({
                    method: "POST",
                    url: "/api/camera/create",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        name: this.camera.name,
                        intercomNumber: this.camera.intercomNumber,

                        location: this.camera.location,
                        viewer: this.camera.viewer,
                        manager: this.camera.manager,

                        awsSecret: this.camera.awsSecret,
                        awsKey: this.camera.awsKey,
                        awsRegion: this.camera.awsRegion,
                        awsChannel: this.camera.awsChannel,

                        launchCommand: this.camera.launchCommand,

                        sipNumber: this.camera.sipNumber,
                        sipPassword: this.camera.sipPassword,

                        deviceId: this.camera.deviceId,
                    }
                }).then((result) => {
                    if (result.data.id) {
                        this.successTitle = "Created new camera!"
                        this.errorTitle = "";
                        this.errorList = [];
                    } else {
                        this.errorTitle = "Cannot create new camera!";
                        this.errorList = [];
                        this.errorList.push("Network connection issue.");
                    }
                }).catch((err) => {
                    console.log(err);
                    this.errorTitle = "Cannot create new camera";
                    this.errorList = [];
                    this.errorList.push("Network connection issue.");
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
