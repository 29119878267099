<template>
    <div v-if="isAdmin">
        <sui-divider></sui-divider>
        <br/>
        <sui-form-field>
            <sui-header dividing class="field-detail">Administrative Configuration</sui-header>
            <p class="field-detail">Only Administrative Staffs can see the following section.</p>
        </sui-form-field>
        <sui-form-field>
            <label>AWS Key</label>
            <input type="text" name="aws-key" placeholder="AWS Key" v-model="camera.awsKey">
        </sui-form-field>
        <sui-form-field>
            <label>AWS Secret</label>
            <input type="text" name="aws-token" placeholder="AWS Secret" v-model="camera.awsSecret">
        </sui-form-field>
        <sui-form-field>
            <label>AWS Region</label>
            <input type="text" name="aws-key" placeholder="AWS Region" v-model="camera.awsRegion">
        </sui-form-field>
        <sui-form-field>
            <label>AWS Channel</label>
            <input type="text" name="aws-key" placeholder="AWS Channel (Optional)" v-model="camera.awsChannel">
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">If leave empty, the system will automatically assign a new channel.</p>
        </sui-form-field>

        <sui-divider></sui-divider>
        <sui-form-field>
            <label>Launch Command</label>
            <input type="text" name="launch-command" placeholder="video-only /dev/video0 pi 1280 720 30 2048" v-model="camera.launchCommand">
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">Command to launch the streaming service on the device. If empty, a default command will be populated.</p>
        </sui-form-field>

        <sui-divider></sui-divider>
        <sui-form-field>
            <label>SIP Number/Name</label>
            <input type="text" name="sip-number" placeholder="_________@_________ OR ______" v-model="camera.sipNumber">
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">A phone call made by this number will be processed by the dashboard. If using phone number, you must provide country code.</p>
        </sui-form-field>
        <sui-form-field>
            <label>SIP Password</label>
            <input type="text" name="sip-password" placeholder="**********" v-model="camera.sipPassword">
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">If leave empty, the dashboard will not forward the phone call. (no country code)</p>
        </sui-form-field>

        <sui-divider></sui-divider>
        <sui-form-field>
            <label>Device ID</label>
            <input type="text" name="device-id" placeholder="Device ID (Optional)" v-model="camera.deviceId">
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">If leave empty, the system will automatically assign a new device ID.</p>
        </sui-form-field>
    </div>
</template>

<script>
    export default {
        name: "CameraFormAdmin",
        props: {
            camera: {
                name: String,

                intercomNumber: String,
                location: Number,
                viewer: Array,
                manager: Array,

                awsSecret: String,
                awsKey: String,
                awsRegion: String,
                awsChannel: String,

                launchCommand: String,

                sipNumber: String,
                sipPassword: String,

                deviceId: String,
            },
            isAdmin: Boolean

        },
    }
</script>

<style scoped>
    p.field-detail {
        margin-top: -10px;
        color: grey;
    }
</style>
